<template>
  <div>
    <Header/>
    <div class="content_text">
        <!-- <router-link :to="{path:'../'}">
          <img class="content_img_1" src="../../assets/content/logo_s.png">
        </router-link> -->
        <img class="content_img_2_2" src="../../assets/task/rank_icon.png">
        <div calss="content_study">成績表</div>
        <div class="rank_div">
          <img class="rank_icon" src="../../assets/task/jifen_icon.png" @click="img">
        </div>
    </div>
    <div style="padding: 20px;" v-if="status">
        <button class="clear-btn round-btn_1" @click="redeemCodes">查看 報數 個人成績表</button>
    </div>
    <div class="lesson-content-title-bar" v-if="status">得分排名</div>
    <div style="padding: 15px;" v-if="status">
        <div class="lesson-content_1">0</div>
        <div class="lesson-content_2">
            <div style="width: 100%;height: 20px;margin-bottom: 0px;">
                <div style="background: rgb(255, 255, 255);height: 20px;border: none;"></div>
            </div>
        </div>
        <div style="margin-bottom: 10px;">
            <div class="lesson-content_3">0</div>
            <div class="lesson-content_4">30</div>
        </div>
    </div>
    <div class="gui-flex" v-if="status">
        <p class="p_one">連續30天全部完成共同任務，獲得</p>
        <p class="p_three"><img src="../../assets/task/medal.png"></p>
        <p class="p_two">一個</p>
    </div>
    <div style="padding: 20px;" v-if="status">
        <div class="gui-align-items-center"></div>
        <div class="gui-rows gui-space-between gui-align-items-centers">
            <div>
                <div class="rank-flex-con">
                    總積分：
                    <div class="number-font">0</div>
                </div>
                <div class="rank-flex-con">
                    本月積分：
                    <div class="number-font">0</div>
                </div>
                
                <div class="rank-flex-con">
                    今日積分：
                    <div class="number-font">0</div>
                </div>
            </div>
        </div>
        <div class="integral_detail">
            <div class="integral_detail_title">積分詳情</div>
            <div class="integral_detail_text"><img src="../../assets/task/point_detail.png"></div>
        </div>
        <div class="gui-align-items-center_1"></div>
        <div class="rank-flex-con">
            <div>你的總排名是：</div>
            <div class="number-font">1/3</div>
        </div>
        <div class="rank-flex-con">
            <div>你的總排名是：</div>
            <div class="number-font">1/3</div>
        </div>
        <div class="ranking">
            <div class="ranking_title">排行榜</div>
            <div class="ranking_text"><img src="../../assets/task/rank_3.png"></div>
        </div>
        <div style="border-bottom: 1px solid rgb(255, 255, 255); margin-top: 10px; margin-bottom: 10px;"></div>
        <div style="margin-top: 20px; margin-bottom: 10px;text-align:left;color:#fff;">班級排名：</div>
    </div>
    <div class="lesson-content-title-bar" style="margin-top: 15px;">打卡記錄</div>
    <div>
        <div class="custom_task" v-for="item in custom_task" :key="item">
            <div class="checkin-item">
                <div style="width: 140px;height: auto;">
                    <img :src="item.img" style="width:100%;height:100%;">
                </div>
                <div class="centerdiv">
                    <div class="checkin-item-text-con">
                        <p class="p1">{{item.title}}</p>
                        <p class="p2">本月你已完成<span>{{item.completed}}天</span></p>
                        <p class="p2">你已無間斷連續打卡<span>{{item.continuous_punching}}天</span></p>
                    </div>
                </div>
            </div>
            <div class="the-month-con"><div >{{item.month}}月</div></div>
            <div class="date-con">
                <div class="date-con-item"></div>
                <div class="date-con-item" v-for="items in item.sum_day" :key="items">
                    <img src="../../assets/task/grade_table/radio.png" class="date-con-image" style="width: 35px; height: 35px;">
                </div>
            </div>
        </div>

        <div class="common_task" v-for="item in common_task" :key="item">
            <div class="checkin-item">
                <div style="width: 140px;height: auto;">
                    <img :src="item.img" style="width:100%;height:100%;">
                </div>
                <div class="centerdiv">
                    <div class="checkin-item-text-con">
                        <p class="p1">{{item.title}}</p>
                        <p class="p2">本月你已完成<span>{{item.completed}}天</span></p>
                        <p class="p2">你已無間斷連續打卡<span>{{item.continuous_punching}}天</span></p>
                    </div>
                </div>
            </div>
            <div class="the-month-con"><div >{{item.month}}月</div></div>
            <div class="date-con">
                <div class="date-con-item"></div>
                <div class="date-con-item" v-for="items in item.sum_day" :key="items">
                    <img src="../../assets/task/grade_table/radio.png" class="date-con-image" style="width: 35px; height: 35px;">
                </div>
            </div>
        </div>
    </div>
    <div class="grace-btdialog-shade_s" v-if="redeemCode_dialog" @click.stop="redeemCode">
            <div class="dialog_s gdIn_s" @click.stop="redeemCode">
                <div style="padding: 10px;">
                    <button class="grace-buttons_s">今日報數</button>
                    <button class="grace-buttons_s" @click="cancel">取消</button>
                </div>
            </div>
        </div>
    <div class="iphoneXbottom"></div>
  </div>
</template>

<style>
.grace-buttons_s{
    background: #fff !important;
    width:100%;
    border: none;
    color: #000000;
    font-size: 18px;
    line-height: 42px;
    padding: 0;
    border-radius: 5px;
    margin-top:1vh;
}
.gdIn_s{
    animation: gdIn-data-v-2f613eb3 .2s linear forwards;
}
.dialog_s{
    width: 100%;
    position: fixed;
    bottom: 0;
}
.grace-btdialog-shade_s{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    background: rgba(0,0,0,.5);
    color: #fff;
}
.date.con-image{
    display: inline-block;
    overflow: hidden;
    position: relative;
}
.date-con-item{
    width: 14.28%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-content: center;
    align-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;

}
.date-con{
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-content: space-between;
    align-content: space-between;
    padding: 5px 17px;
    border-bottom: 4px solid #fff /* margin-bottom: 10px; */;
}
.the-month-con{
    height: 36px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    font-family: Helvetica Neue;
    font-size: 17px;
    text-align: center;
    color: #fff;
    line-height: 36px;
}
p{
    margin:0;
    color:#fff;
}
.checkin-item-text-con .p2 span{
    margin-left: 2px;
    font-size: 20px;
    font-weight: bold;
}
.checkin-item-text-con .p2{
    margin-top: 5px;
    font-family: Helvetica Neue;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
}
.checkin-item-text-con .p1{
    font-family: Helvetica Neue;
    font-size: 22px;
    line-height: 26px;
    text-align: left;
}
.checkin-item-text-con{
    padding: 5px 0;
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 5px;
    margin-right: 5px;
    height: -webkit-fit-content;
    height: fit-content;
}
.checkin-item .centerdiv{
    height: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.checkin-item{
    height: auto;
    min-height: 75px;
    display: flex;
}
.iphoneXbottom{
    height:10vh;
}
.lesson-content-title-bar {
    height: 40px;
    background: rgba(0,0,0,.5);
    padding-left: 15px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 700;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
.number-font{
    font-size: 36px;
    font-weight: 300;
    margin-left: auto;
}
.rank-flex-con{
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    color:#fff;
}
.gui-align-items-center_1{
    border-bottom: 1px solid rgb(255, 255, 255);
    padding-top: 20px;
    margin-bottom: 10px;
    clear: both;
}
.integral_detail_text{
    width:30%;
    height:100%;
    text-align:left;
}
.integral_detail_title{
    width:70%;
    height:100%;
    text-align:right;
    color:#fff;
}
.integral_detail div{
    float: left;
}
.integral_detail{
    width:50%;
    height:15vh;
    line-height: 18vh;
    float: left;
}
.ranking{
    width:100%;
    height:8vh;
    line-height: 10vh;
}
.ranking img{
    margin: -10px 8px;
    width: 40px;
    height: 40px;
}
.ranking_title{
    width:85%;
    height:100%;
    text-align:right;
    margin-top:-8px;
    color:#fff;
}
.ranking div{
    float: left;
}
.ranking_text{
    width:15%;
    height:100%;
    text-align:left;
}
.integral_detail img{
    margin: -10px 8px;
    width: 30px;
    height: 30px;
}

.number-font{
    font-size: 36px;
    font-weight: 300;
}
.rank-flex-con{
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
}
.gui-align-items-centers {
    width:50%;
    height:100%;
    color:#fff;
    align-items: center;
    float: left;
}
.gui-space-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.gui-align-items-center{
    border-bottom: 1px solid rgb(255, 255, 255);
    margin-top: 20px;
    margin-bottom: 10px;
}
.p_three{
    height:42px;
    width:9%;
    float:left;
}
.p_one{
    height:42px;
    width:76%;
    float:left;
    line-height:42px;
}
.p_two{
    height:42px;
    width:15%;
    float:left;
    line-height:42px;
}
.gui-flex{
    width:100%;
    height: 30px;
    color:#fff;
}
.gui-flex img{
    width: 25px;
    height: 42px;
}
.lesson-content_4{
    font-size: 80%;
    width: 50%;
    text-align: right;
    float:left;
    color:#fff;
}
.lesson-content_1{
    margin-left: -1.9%;
    text-align: left;
    color: #fff;
}
.lesson-content_2{
    border: 2px solid rgb(255, 255, 255);
    margin-bottom: 5px;
}
.lesson-content_3{
    font-size: 80%;
    width: 50%;
    text-align: left;
    float:left;
    color:#fff;
}
.lesson-content-title-bar{
    text-align: left;
    color:#fff;
    height: 40px;
    background: rgba(0,0,0,.5);
    padding-left: 15px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 700;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}
.round-btn_1 {
    width: 100%;
    color: #fff;
    line-height: 130%;
    font-size: 16px;
    padding: 7px 20px;
    border: 1px solid #fff;
    border-radius: 999999px;
    background-color: initial;
    -webkit-transition: all .3s cubic-bezier(.215,.61,.355,1);
    transition: all .3s cubic-bezier(.215,.61,.355,1);
    padding: 7px 10px;
}
.rank_div{
  margin-left: auto;
  margin-right: 17px;
  display: flex;
}
.rank_icon{
  height: 40px;
  margin-right: 8px;
  width: 35px;
}
.content_text{
    padding-left: 17px;
    height: 76px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    font-family: Helvetica Neue;
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: #fff;
    border-bottom: 1px solid #fff
  }
  .content_img_1{
    margin-right: 13px;
    width: 60px;
    height: 55px;
  }
  .content_img_2_2{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
</style>
<script>
import Header from "../Header";
export default {
  components:{Header},
  data(){
    return {
      status:false,
    //   自訂任務
      custom_task:[],
    //   共同任務
      common_task:[], 
      redeemCode_dialog:false,
    }
  },
  created(){
    if(this.$store.state.login_verify){
      this.status = true;
    }else{
      this.status = false;
    }

    let date = new Date();
    let y = date.getFullYear()
    let m = date.getMonth() + 1
    
    var date2 = new Date(y,m,0)
    // 日 本月最后一天
    var rq = date2.getDate()

    //   自訂任務
    this.custom_task = [
        {
            img:require('@/assets/task/grade_table/img_1.jpg'),
            title:'自訂任務一',
            // 已完成天數
            completed:1,
            // 連續打卡 天數
            continuous_punching:3,
            // 當前月份
            month:m,
            // 這個月總共有多少天
            sum_day:rq,
        }
    ];

    //   共同任務
    this.common_task = [
        {
            img:require('@/assets/task/common_task/1.jpg'),
            title:'今日報數',
            // 已完成天數
            completed:4,
            // 連續打卡 天數
            continuous_punching:5,
            // 當前月份
            month:m,
            // 這個月總共有多少天
            sum_day:rq,
        },
        {
            img:require('@/assets/task/common_task/2.jpg'),
            title:'話術練習',
            // 已完成天數
            completed:6,
            // 連續打卡 天數
            continuous_punching:7,
            // 當前月份
            month:m,
            // 這個月總共有多少天
            sum_day:rq,
        },
        {
            img:require('@/assets/task/common_task/3.jpg'),
            title:'每天閱讀',
            // 已完成天數
            completed:8,
            // 連續打卡 天數
            continuous_punching:9,
            // 當前月份
            month:m,
            // 這個月總共有多少天
            sum_day:rq,
        }
    ];

  },
  computed:{
      login_verify(){
        return this.$store.state.login_verify;
      }
  },
  watch:{
    login_verify(val){
      if(val){
        this.status = true;
      }else{
        this.status = false;
      }
    }
  },
  methods:{
    redeemCodes(){
        this.redeemCode_dialog = true;
    },
    redeemCode(val){
        // 点击子元素时 阻止触发父级方法
        let event1 = val.currentTarget;
        let event2 = val.target;
        // 当event1 == event2 则表示点击的是子级以外的所有父级内容 则关闭输入兑换码
        if(event1 == event2){   
            this.redeemCode_dialog = false;
        }
    },
    img(){
        alert('記分規則');
    },
    cancel(){
        this.redeemCode_dialog = false;
    }
  }
}
</script>

